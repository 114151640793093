import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from 'lodash';
import { LoginResponse } from '../_models/Responses/Account/loginResponse';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AccountRoles } from '../_enums/accountRoles';
import { RoleGroups } from '../_enums/roleGroups';
import { environment } from '../../environments/environment';
import { UserAccount, UserAccountsResponse } from '../_models/Responses/Account/userAccountsResponse';
import { BaseResponse } from '../_models/Responses/baseResponse';
import { AccountDetails } from '../_models/Requests/Account/accountDetails';
import { Organization, OrganizationsResponse } from '../_models/Responses/Account/organizationsResponse';
import { ApplicationHostsResponse } from '../_models/Responses/Account/ApplicationHostsResponse';

@Injectable()
export class AccountService {
    private userKey = 'authenticatedUser';
    constructor(private apiService: ApiService, private http: HttpClient) {

    }   
    getAccounts() {
        let url = 'api/account/getUserAccounts';
        // let searchParamStr = {};
        // searchParamStr = "?searchParam=" + JSON.stringify(searchFilter);
        return this.http.get<UserAccountsResponse>(url);// + searchParamStr
    }
    getAccountDetails(accountId: number){
        let url = 'api/account/getAccountDetails';
        return this.http.get<UserAccount>(url + this.apiService.generateParametersStr({ accountId: accountId }));
    }
    setAccountDetails(details: AccountDetails){
        let url = 'api/account/setAccountDetails';
        return this.http.post<BaseResponse>(url, details);
    }
    getOrganizations(organizationId: number = null){
        let url = 'api/account/getOrganizations';
        return this.http.get<OrganizationsResponse>(url + this.apiService.generateParametersStr({ organizationId: organizationId }));
    }
    setOrganizationDetails(details: Organization){
        let url = 'api/account/setOrganizationDetails';
        return this.http.post<BaseResponse>(url, details);
    }
    getApplicationHosts(applicationHostId: number = null){
        let url = 'api/account/getApplicationHosts';
        return this.http.get<ApplicationHostsResponse>(url + this.apiService.generateParametersStr({ applicationHostId: applicationHostId }));
    }
    getUserImagePath(accountId){
        return environment.apiEndPoint + '/uploaded/users/' + accountId + '.png';
    }
}